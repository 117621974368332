import React, { useState, useRef } from 'react';
import Layout from '../Layout/Layout';
import { Button, Container, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { baseUrl } from '../Redux/Slice/Aadhar';
import Swal from 'sweetalert2';
import axios from 'axios';
import forge from 'node-forge';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        padding: theme.spacing(4),
        backgroundColor: '#f5f5f5',
        borderRadius: '8px',
    },
    submitButton: {
        marginTop: theme.spacing(4),
        backgroundColor: '#007bff',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#0056b3',
        },
    },
    formTitle: {
        marginBottom: theme.spacing(2),
        color: '#007bff',
    },
}));

const AbhaOtp = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [otp, setOtp] = useState('');
    const [txnId, setTxnId] = useState(false);
    const [mobileNo, setMobileNo] = useState('');
    const [abhaSegments, setAbhaSegments] = useState({
        prefix: '',
        part1: '',
        part2: '',
        part3: ''
    });

    // Refs for the text fields
    const part1Ref = useRef(null);
    const part2Ref = useRef(null);
    const part3Ref = useRef(null);
    const mobileRef = useRef(null);

    const publicKeyPem = `-----BEGIN PUBLIC KEY-----
    MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAstWB95C5pHLXiYW59qyO
    4Xb+59KYVm9Hywbo77qETZVAyc6VIsxU+UWhd/k/YtjZibCznB+HaXWX9TVTFs9N
    wgv7LRGq5uLczpZQDrU7dnGkl/urRA8p0Jv/f8T0MZdFWQgks91uFffeBmJOb58u
    68ZRxSYGMPe4hb9XXKDVsgoSJaRNYviH7RgAI2QhTCwLEiMqIaUX3p1SAc178ZlN
    8qHXSSGXvhDR1GKM+y2DIyJqlzfik7lD14mDY/I4lcbftib8cv7llkybtjX1Aayf
    Zp4XpmIXKWv8nRM488/jOAF81Bi13paKgpjQUUuwq9tb5Qd/DChytYgBTBTJFe7i
    rDFCmTIcqPr8+IMB7tXA3YXPp3z605Z6cGoYxezUm2Nz2o6oUmarDUntDhq/PnkN
    ergmSeSvS8gD9DHBuJkJWZweG3xOPXiKQAUBr92mdFhJGm6fitO5jsBxgpmulxpG
    0oKDy9lAOLWSqK92JMcbMNHn4wRikdI9HSiXrrI7fLhJYTbyU3I4v5ESdEsayHXu
    iwO/1C8y56egzKSw44GAtEpbAkTNEEfK5H5R0QnVBIXOvfeF4tzGvmkfOO6nNXU3
    o/WAdOyV3xSQ9dqLY5MEL4sJCGY1iJBIAQ452s8v0ynJG5Yq+8hNhsCVnklCzAls
    IzQpnSVDUVEzv17grVAw078CAwEAAQ==
    -----END PUBLIC KEY-----`;

    const handleAbhaChange = (event) => {
        const { name, value } = event.target;
        setAbhaSegments((prev) => ({
            ...prev,
            [name]: value,
        }));

        // Move to the next field if the length matches the max length
        if (name === 'prefix' && value.length === 2) {
            part1Ref.current.focus();
        } else if (name === 'part1' && value.length === 4) {
            part2Ref.current.focus();
        } else if (name === 'part2' && value.length === 4) {
            part3Ref.current.focus();
        }
    };

    const handleMobileChange = (event) => {
        const { value } = event.target;
        if (/^\d*$/.test(value) && value.length <= 10) {
            setMobileNo(value);
        }
    };

    const handleAbhaSubmit = async (event) => {
        event.preventDefault();
        const { prefix, part1, part2, part3 } = abhaSegments;
        const formattedAbha = `${prefix}-${part1}-${part2}-${part3}`;

        if (formattedAbha.match(/^\d{2}-\d{4}-\d{4}-\d{4}$/)) {
            console.log(formattedAbha)
            try {
                const encryptedData = forge.pki.publicKeyFromPem(publicKeyPem).encrypt(formattedAbha, 'RSA-OAEP');
                const encryptedAbhaNo = forge.util.encode64(encryptedData);

                const formData = new FormData();
                formData.append('loginId', encryptedAbhaNo);

                const response = await axios.post(`${baseUrl}request-otp-AabhaAddress`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                if (response.data.message) {
                    toast.success(response.data.message);
                    if (response.data.txnId === "BadRequest") {
                        return;
                    }
                    setTxnId(true);
                } else {
                    toast.error(response.error.message || 'Failed to generate OTP');
                }
            } catch (error) {
                console.error('Error fetching ABHA details:', error);
                toast.error('An error occurred while fetching ABHA details');
            }
        } else {
            toast.error('Invalid ABHA format.');
        }
    };

    const handleOtpSubmit = async (event) => {
        event.preventDefault();
        if (otp && mobileNo) {
            try {
                const encryptedOtp = forge.pki.publicKeyFromPem(publicKeyPem).encrypt(otp, 'RSA-OAEP');
                const encryptedOTP = forge.util.encode64(encryptedOtp);

                const body = {
                    otpValue: encryptedOTP,
                    mobile: mobileNo
                };

                const response = await axios.post(`${baseUrl}verify-by-abhaAddress`, body, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                if (response.data.message) {
                    toast.success(response.data.message);
                    if (response.data.message === 'This account already exists') {
                        toast.success(`Your ABHA account is already registered ${response.data.abhaProfile.abhaNumber}`);
                    }
                } else {
                    toast.error(response.error.message || 'Failed to verify OTP');
                }
            } catch (error) {
                console.error('Error verifying OTP:', error);
                toast.error('An error occurred while verifying OTP. Please try again.');
            }
        } else {
            toast.error('Please enter both OTP and mobile number.');
        }
    };

    return (
        <Layout>
            <Container maxWidth="lg">
                <div>
                    <Typography variant="h6" className={classes.formTitle}>
                        ABHA Verification by OTP (Registered Mobile Number on ABHA)
                    </Typography>
                    {!txnId ? (
                        <form onSubmit={handleAbhaSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={2}>
                                    <TextField
                                        variant="outlined"
                                        label="Abha Address"
                                        name="prefix"
                                        value={abhaSegments.prefix}
                                        onChange={handleAbhaChange}
                                        inputProps={{ maxLength: 2 }} // Limit to 2 digits
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        variant="outlined"

                                        name="part1"
                                        value={abhaSegments.part1}
                                        onChange={handleAbhaChange}
                                        inputProps={{ maxLength: 4 }} // Limit to 4 digits
                                        inputRef={part1Ref}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        variant="outlined"

                                        name="part2"
                                        value={abhaSegments.part2}
                                        onChange={handleAbhaChange}
                                        inputProps={{ maxLength: 4 }} // Limit to 4 digits
                                        inputRef={part2Ref}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        variant="outlined"

                                        name="part3"
                                        value={abhaSegments.part3}
                                        onChange={handleAbhaChange}
                                        inputProps={{ maxLength: 4 }} // Limit to 4 digits
                                        inputRef={part3Ref}
                                    />
                                </Grid>
                            </Grid>
                            <Button
                                type="submit"
                                variant="contained"
                                className={classes.submitButton}
                            >
                                Request OTP
                            </Button>
                        </form>
                    ) : (
                        <form onSubmit={handleOtpSubmit}>
                            <Grid container spacing={2}>
                                <Grid item s={3}>
                                    <TextField
                                        variant="outlined"
                                        label="Mobile Number"
                                        value={mobileNo}
                                        onChange={handleMobileChange}
                                        inputProps={{ maxLength: 10 }} // Limit to 10 digits
                                        inputRef={mobileRef}
                                    />
                                </Grid>
                                <Grid item s={3}>
                                    <TextField
                                        variant="outlined"
                                        label="OTP"
                                        value={otp}
                                        onChange={(e) => setOtp(e.target.value)}
                                        inputProps={{ maxLength: 6 }} // Limit to 6 digits
                                    />
                                </Grid>
                            </Grid>
                            <Button
                                type="submit"
                                variant="contained"
                                className={classes.submitButton}
                            >
                                Verify OTP
                            </Button>
                        </form>
                    )}
                </div>
            </Container>
        </Layout>
    );
};

export default AbhaOtp;
